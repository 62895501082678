import { HeadFC, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Tik from "../assets/img/tik.png"
import {
  Budget,
  Country,
  Email,
  Name,
  PhoneNumber,
  Recaptcha,
  TextArea,
} from "../components/FormFields"
import SEO from "../components/SEO"
import useFormControls from "../hooks/useContactFormControls"
import useSiteMetadata from "../hooks/useSiteMetadata"
import ArrowWhite from "../assets/img/arrow-right.webp"
import TestimonialSlider from "../components/DashboardTestimonials/TestimonialSlider"
import { useContextValues } from "../context/NavBarContext"

const ContactPage = () => {
  const {
    handleChange,
    errorMessage,
    formValue,
    handleSubmit: handleContFormSubmit,
    handleToken,
    flags,
    recaptchaRef,
    customPhoneDropdownRef,
    toggleDropdown,
    renderRecaptcha,
    setToggleDropdown,
    shouldRenderRecaptcha,
    setSelectedCountryFlag,
    setSelectedCountryDialCode,
  } = useFormControls()

  const { currentPath } = useContextValues()

  const handleSubmit = async e => {
    e.preventDefault()
    await handleContFormSubmit(e, {
      pathName: currentPath,
      ignoreFields: ["companyName", "phoneNumber"]
    })
  }
  return (
    <main>
      <section className="result-sec contact-form-page md:py-[8.75rem] py-[5.625rem] bg-[url('../assets/img/contact.webp')] bg-no-repeat bg-cover">
        <div className="container">
          <div className="flex flex-wrap justify-between align-middle">
            <div className="max-w-full w-full xl:max-w-[51%] md:max-w-[45%] xl:pr-20 md:pr-10 pr-0 ">
              <h1 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] md:pb-10 pb-6 font-blancomedium">
                Get <span className="italic text-primary">real estimates</span> and ideal solutions for your project
                <span className="inline-block text-orangecol">.</span>
              </h1>
              <ul className="md:pb-20 pb-10">
                <li className="flex items-center text-black md:text-lg text-sm pb-5">
                  <img
                    src={Tik}
                    alt=""
                  />
                  <span className="ml-[0.625rem]">We will respond to you within 24 hours.</span>
                </li>
                <li className="flex items-center text-black md:text-lg text-sm pb-5">
                  <img
                    src={Tik}
                    alt=""
                  />
                  <span className="ml-[0.625rem]">We’ll sign an NDA if requested.</span>
                </li>
                <li className="flex items-center text-black md:text-lg text-sm">
                  <img
                    src={Tik}
                    alt=""
                  />
                  <span className="ml-[0.625rem]">Access to dedicated product specialists.</span>
                </li>
              </ul>
              <div className="flex items-center">
                <div className="bg-[#D9D9D9] w-20 min-w-[5rem] h-20 border border-[#CDCAF4] lg:w[8.75rem] lg:min-w-[8.75rem] lg:h-[8.75rem] rounded-full">
                  <StaticImage src="../assets/img/vb.webp" alt="CEO headshot image" className="min-w-full w-full h-full rounded-full" />
                </div>
                <div className="flex flex-col pl-5">
                  <h3 className="font-blancomedium text-black pb-2 text-lg">
                    Inquiries
                    <span className="inline-block text-orangecol">.</span>
                  </h3>
                  <a
                    href="mailto:sales@techuz.com"
                    className="font-gorditaregular underline text-black transition-all duration-500 hover:text-primary text-2xl leading-[1.875rem]"
                  >
                    sales@techuz.com
                  </a>
                </div>
              </div>

            </div>
            <div className="md:mt-0 mt-16 xl:w-[49%] md:w-[55%] w-full relative">
              <form
                className="contact-form-main bg-white-200 border-4 border-cream rounded-[1.25rem] p-10 lg:p-[30px] xl:p-6"
                onSubmit={handleSubmit}
                onChange={shouldRenderRecaptcha}
              >
                <h3 className="btch-big bg-[#E8E3FF] inline-block rounded-[6.25rem] text-[0.875rem] italic mb-5  font-gorditamedium text-xs sm:text-sm  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]">1. Tell us about your company</h3>
                <div className="relative flex justify-between pb-6 md:flex-nowrap flex-wrap">
                  <div className=" w-full relative">
                    <Name
                      fieldCss="text-sm leading-[1.375rem] w-full text-black pt-[0.875rem] pb-3 px-5 bg-transparent rounded-[0.625rem] border border-white-800 focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                      errorCss="text-sm text-red absolute bottom-[-23px]"
                      formValue={formValue}
                      handleChange={handleChange}
                      errorMessage={errorMessage}
                    />
                  </div>
                </div>
                <div className="relative max-w-full w-full pb-6">
                  <Email
                    fieldCss="text-sm leading-[1.375rem] w-full text-black pt-[0.875rem] pb-3 px-5 bg-transparent rounded-[0.625rem] border border-white-800 focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                    errorCss="text-sm text-red absolute bottom-0"
                    formValue={formValue}
                    handleChange={handleChange}
                    errorMessage={errorMessage}
                  />
                </div>
                <div className=" flex justify-between pb-6 lg:flex-nowrap flex-wrap">

                  <div className="relative xl:max-w-[calc(50%-8px)] lg:max-w-[calc(50%-10px)] max-w-full w-full pb-6">
                    <Country
                      fieldCss="select-budget bg-[url('../assets/img/Path1.svg')] bg-no-repeat bg-[center_right_15px] appearance-none text-sm leading-[1.375rem] w-full text-black pt-[0.875rem] pb-3 px-5 bg-transparent rounded-[0.625rem] border border-white-800 focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                      errorCss="text-sm text-red absolute bottom-0"
                      formValue={formValue}
                      handleChange={handleChange}
                      errorMessage={errorMessage}
                    />
                  </div>

                  <div className="relative xl:max-w-[calc(50%-8px)] lg:max-w-[calc(50%-10px)] max-w-full w-full pb-6">
                    <PhoneNumber
                      fieldCss="text-sm leading-[1.375rem] w-full text-black pt-[0.875rem] pb-3 px-5 bg-transparent rounded-[0.625rem] border border-white-800 focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                      errorCss="text-sm text-red absolute bottom-0"
                      toggleDropdown={toggleDropdown}
                      flags={flags}
                      customPhoneDropdownRef={customPhoneDropdownRef}
                      setSelectedCountryFlag={setSelectedCountryFlag}
                      setSelectedCountryDialCode={setSelectedCountryDialCode}
                      setToggleDropdown={setToggleDropdown}
                      handleChange={handleChange}
                      errorMessage={errorMessage}
                    />
                  </div>
                </div>
                <h3 className="btch-big bg-[#E8E3FF] inline-block rounded-[6.25rem] text-[0.875rem] italic mb-5  font-gorditamedium text-xs sm:text-sm  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]">2. Tell us more about your project</h3>
                <div className="relative flex justify-between md:flex-nowrap flex-wrap">
                  <div className=" w-full pb-6">
                    <TextArea
                      fieldCss="h-40 resize-none text-sm leading-[1.375rem] w-full text-black pt-[0.875rem] pb-3 px-5 bg-transparent rounded-[0.625rem] border border-white-800 focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                      errorCss="text-sm text-red absolute bottom-[7px]"
                      formValue={formValue}
                      handleChange={handleChange}
                      errorMessage={errorMessage}
                      name='message'
                      placeholder="Describe your project briefly"
                    />
                  </div>
                </div>
                <div className="relative flex justify-between md:pb-10 pb-0 md:flex-nowrap flex-wrap">
                  <div className=" w-full md:pb-0 pb-10">
                    <Budget
                      fieldCss="select-budget bg-[url('../assets/img/Path1.svg')] bg-no-repeat bg-[center_right_15px] appearance-none text-sm leading-[1.375rem] w-full text-black pt-[0.875rem] pb-3 px-5 bg-transparent rounded-[0.625rem] border border-white-800 focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                      errorCss="text-sm text-red absolute bottom-[15px]"
                      formValue={formValue}
                      handleChange={handleChange}
                      errorMessage={errorMessage}
                    />
                  </div>
                </div>
                {renderRecaptcha && (
                  <div className="flex justify-between md:pb-12 pb-10 md:flex-nowrap flex-wrap relative pt-0 scale-90 origin-[0_0] md:transform-none">
                    <Recaptcha
                      errorCss="absolute text-red text-sm md:bottom-[20px] bottom-[17px]"
                      recaptchaRef={recaptchaRef}
                      handleToken={handleToken}
                      errorMessage={errorMessage}
                    />
                  </div>
                )}
                <div className="flex flex-wrap items-center justify-between md:flex-nowrap">
                  <p className="text-xs text-[#484848] leading-[1.125rem] mb-5 min-[991px]:mb-0 w-full">By sending this form I confirm that I <br /> have read and accept the
                    <Link
                      to="/privacy-policy/"
                      className="font-gorditamedium italic underline"
                    > Privacy Policy</Link>
                  </p>
                  <button className="inline-flex items-center bg-white-200 justify-start inline-white text-black text-base rounded-3xl pl-[30px] pt-[12px] pb-[10px] relative pr-[52px] leading-[1.313rem] font-gorditamedium group ease-in duration-300 border border-black">
                    Submit
                    <span className="inline-flex bg-primary justify-center items-center rounded-full ml-[0.625rem]  h-[34px] w-[34px] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-black absolute right-[5px] top-[5px]">
                      <img
                        src={ArrowWhite}
                        alt="ArrowRight"
                        className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                      />
                      <img
                        src={ArrowWhite}
                        alt="ArrowRight"
                        className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                      />
                    </span>
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="lg:py-[3.438rem] md:py-20 py-10">
        <div className="container">
          <div className="w-full text-center lg:pb-10 pb-9">
            <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic py-[0.563rem] pb-[0.313rem] px-[1.5rem] rounded-[6.25rem] mb-[0.625rem]">Testimonials</span></div>
            <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium lg:max-w-[47rem] my-0 mx-auto">
              Here’s what  <span className="italic text-primary">our clients say</span> about us
              <span className="inline-block text-orangecol">.</span>
            </h2>
            <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">They’ve praised and highly rated our work on various review platforms.</p>
          </div>

          <TestimonialSlider />
        </div>
      </section >
    </main >
  )
}

export default ContactPage

export const Head: HeadFC = ({ location }) => {
  const { siteUrl } = useSiteMetadata()
  return (
    <SEO
      title="Contact us for Your Next Web or Mobile App Project | Techuz InfoWeb"
      canonical={`${siteUrl}${location.pathname}`}
      description="Techuz is located in Ahmedabad, India and we provide sales and development services around the globe from here. Do you have a sales inquiry or want to contact us? Please call us or fill out the form or just drop an email."
    />
  )
}
